import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCheck } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Yexon
			</title>
			<meta name={"description"} content={"Відкрийте для себе тепло кожної чашки!"} />
			<meta property={"og:title"} content={"Головна | Yexon"} />
			<meta property={"og:description"} content={"Відкрийте для себе тепло кожної чашки!"} />
			<meta property={"og:image"} content={"https://bet.yexonovist.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://bet.yexonovist.com/img/cup.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://bet.yexonovist.com/img/cup.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://bet.yexonovist.com/img/cup.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://bet.yexonovist.com/img/cup.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://bet.yexonovist.com/img/cup.png"} />
			<meta name={"msapplication-TileImage"} content={"https://bet.yexonovist.com/img/cup.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="50px 0 50px 0" sm-padding="80px 0 50px 0" quarkly-title="Hero-11" sm-text-align="center">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				lg-align-items="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					padding="80px 0px 80px 0px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					sm-padding="0 0px 50px 0px"
				>
					<Text margin="0px 0px 30px 0px" font="normal 700 42px/1.2 --fontFamily-sans" sm-font="normal 700 36px/1.2 --fontFamily-sans" color="--darkL2">
						Yexon
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
					Ласкаво просимо до кав'ярні Yexon, де кожен ковток відчувається як вдома. Незалежно від того, починаєте ви свій ранок, робите обідню перерву чи завершуєте робочий день, наші двері відчинені, щоб запропонувати вам ідеальну чашку кави в затишній обстановці. Тут головне - насолоджуватися цими маленькими моментами.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-width="100%"
						flex-direction="column"
					>
						<Button
							padding="15px 38px 15px 38px"
							background="--color-primary"
							font="normal 700 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							color="--dark"
							letter-spacing="1px"
							margin="0px 0px 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--primary"
							border-radius="25px"
							hover-color="--darkL1"
							hover-background="rgba(215, 26, 89, 0.03)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Знайти нас
						</Button>
					</Box>
				</Box>
				<Image
					src="https://bet.yexonovist.com/img/1.jpg"
					display="block"
					width="40%"
					object-fit="cover"
					sm-height="500px"
					height="100%"
					lg-width="100%"
					lg-height="auto"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://bet.yexonovist.com/img/2.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://bet.yexonovist.com/img/3.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					У Yexon ми віримо, що це більше, ніж просто подача чудової кави, ми прагнемо створити незабутній досвід.
				</Text>
			</Box>
		</Section>
		<Section
			padding="50px 0 140px 0"
			sm-padding="60px 0 60px 0"
			color="--dark"
			md-padding="60px 0 60px 0"
			quarkly-title="Advantages/Features-27"
		>
			<Override slot="SectionContent" max-width="1220px" md-align-items="center" sm-min-width="280px" />
			<Box
				border-radius="24px"
				margin="0px 0px 80px 0px"
				md-margin="0px 0px 40px 0px"
				width="55%"
				md-width="80%"
				sm-width="100%"
			>
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline2"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					Чому варто обрати Yexon?
				</Text>
			</Box>
			<Box
				lg-flex-wrap="wrap"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-gap="36px"
				sm-grid-template-columns="repeat(1, 1fr)"
				sm-grid-gap="8px"
				align-items="center"
			>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Icon
						category="fa"
						icon={FaCheck}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--primary"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Якісні інгредієнти: 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Кожна чашка вариться з органічно вирощених зерен, що гарантує чистий і міцний смак.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Icon
						category="fa"
						icon={FaCheck}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--primary"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Експертні бариста: 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Наші бариста, навчені досконалості, готують кожен напій з ретельністю та точністю.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Icon
						category="fa"
						icon={FaCheck}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--primary"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Орієнтованість на громаду: 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Ми цінуємо кожного відвідувача і прагнемо зробити Yexon вашим улюбленим місцем зустрічі.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Icon
						category="fa"
						icon={FaCheck}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--primary"
						md-margin="0px 0px 16px 0px"
						text-align="center"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Розслаблююча атмосфера: 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Насолоджуйтесь кавою в комфортній, продуманій обстановці, яка відчувається як продовження вашої вітальні.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});